.moduleWrapper {
  :global {
    .listStatuses {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      gap: 5px;
      flex-wrap: wrap;
      border-bottom: 1px solid #dbdbdb;
      padding-bottom: 20px;
      margin-bottom: 20px;

      .status {
        background: #f7f7f7;
        padding: 10px 10px;
        border: 1px solid #d3d0d0;
        text-align: center;
        border-radius: 10px;
        width: 100px;
        cursor: pointer;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 5px;

        &:hover, &.active {
          border-color: red !important;
          background: #d3d0d0;
        }
      }
    }
  }
}
