:global {
  .taskUpdateForm {
    .ant-form-item {
      margin-bottom: 10px !important;
    }
  }
}


.moduleWrapper {
  :global {
    .printItems {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 20px;

      .printItem {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 10px;
        box-sizing: border-box;
        width: 7cm;
        height: 5cm;
        //border: 1px solid black;

        .top, .bottom {
          display: flex;
          flex-direction: column;
          gap: 5px;
        }

        .bottom {
          flex-direction: row !important;
        }

        .bottom {
          font-weight: bold;
        }

        .barAndPhone {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: flex-start;
        }

        .phone {
          margin-top: -15px;
        }

        .codeWrapper {
          width: 50%;
        }

        svg {
          margin-left: -4px;
          width: 100%;
          height: fit-content;
        }

        @media print {
          page-break-before: always;
        }
      }
    }
  }
}
