:global {
  .qrPreview[data-role="staff"] {
    width: 145px !important;
  }

  .footerAction {
    display: flex;
    gap: 10px;
  }

  tr.activeRow {
    background: #fff2de !important;
  }

  .tableContent {
    width: 100%;
    border-collapse: collapse;

    thead {
      background: #e7e7e7;
    }

    thead tr th.no,
    tbody tr td.no {
      width: 20px;
      text-align: center;
    }

    thead tr th.actions,
    tbody tr td.actions {
      width: 100px;
      //text-align: right;
    }

    th, td {
      padding: 5px 10px;
      text-align: left;
      border: 1px solid #dbdbdb;
      font-size: 14px;
    }
  }

  .modalContent {
    height: calc(100vh - 250px);
    overflow: auto;
  }

  .twoColumns {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    column-gap: 20px;

    .submitRow {
      width: 100%;
    }

    > div {
      width: calc(50% - 10px);
    }
  }

  .threeColumns {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    column-gap: 20px;

    .submitRow {
      width: 100%;
    }

    > div {
      width: calc(100%/3 - 40px/3);
    }
  }
  .fiveColumns {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    column-gap: 20px;

    .submitRow {
      width: 100%;
    }

    > div {
      width: calc(100%/5 - 80px/5);
    }
  }

  .fourColumns {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    column-gap: 20px;

    .submitRow {
      width: 100%;
    }

    > div {
      width: calc(100%/4 - 60px/4);
    }
  }
}

.moduleWrapper {
  :global {

    .manualLayout {
      .row {
        display: flex;
        flex-direction: row;
        column-gap: 20px;
      }
      .left, .right {
        width: calc(50% - 10px);
      }
      .full {
        width: calc(100%);
      }
    }

    .commonTable {
      gap: 10px;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
    }

    .tableTopActions {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      border-bottom: 1px solid #ddd;
      padding-bottom: 10px;
      font-weight: 500;
    }

    .tablePagination {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: row;
      width: 100%;
      margin-top: 10px;
    }

    label {
      font-weight: 500;
    }
  }
}
