:global {
  .taskUpdateForm {
    .ant-form-item {
      margin-bottom: 10px !important;
    }
  }

  .topMenus {
    display: flex;
    flex-direction: row;
    gap: 20px;
    align-items: center;
  }

  .taskContent {
    display: flex;
    gap: 10px;
    flex-direction: column;
  }

  .blankPhone {
    width: 250px;
    height: 250px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    background: #e1e1e1;
    padding: 17px;
    box-sizing: border-box;
    text-align: center;
    font-size: 20px;
  }

  .printTableHeader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
  }

  [for="contractPrice"] {
    width: 100%;
  }
  .priceActions {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;

    .right {
      display: flex;
      gap: 5px;
      //font-size: 10px;
      //font-weight: bold;
      margin-right: -13px;

      .price {
        &:hover {
          color: blue;
        }
        cursor: pointer;
      }
    }
  }

  .ant-modal .ant-modal-content {
    padding: 15px 15px !important;
  }

  .actionAndImage {
    display: flex;
    flex-direction: row;
    gap: 15px;
    align-items: flex-start;
    background: #e9e9e9;
    padding: 20px;

    .qrArea {
      display: flex;
      align-items: center;
      gap: 0;
      flex-direction: column;

       img {
         height: 100px;
         width: 100px;
         //width: 100% !important;
       }
    }
  }
  .taskActions {
    justify-content: center;
    flex-direction: row;
    display: flex;
    gap: 10px;
    flex-wrap: wrap;

    .activeStatus {
      border: none;
      box-shadow: none;
      background: #fff1e6;
    }

    button[disabled] {
      opacity: 0.5;
    }

    button {
      //width: calc((100% - 40px) / 5) !important;
      width: calc((100% - 30px) / 4) !important;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: row;
      gap: 5px;
      padding: 20px 0;
    }
  }
}

.moduleWrapper {
  font-family: Tahoma, sans-serif;
  font-size: 16px;

  :global {
    .wrapper {
      display: flex;
      flex-direction: row;
      height: 100vh;
      background: #f7f7f7;

      .sidebar {
        display: none;
        flex-direction: column;
        justify-content: space-between;

        width: 185px;
        background: #ebebeb;
        box-sizing: border-box;
        border-right: 1px solid #b9b9b9;

        .copyright {
          width: 100%;
          text-align: center;
          font-size: 14px;
          padding: 10px 0px;
        }

        .logo {
          text-transform: uppercase;
          text-align: center;
          font-size: 20px;
          height: 44px;
          display: flex;
          background: rgba(3, 38, 60, 0.8980392157);
          justify-content: center;
          align-items: center;
          flex-direction: row;

          a {
            display: flex;
            color: #FFF;
            text-decoration: none;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            gap: 10px;
          }
        }

        .user {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          gap: 15px;
          padding: 15px 0px;

          .logout {
            cursor: pointer;
            display: flex;
          }
        }

        .links {
          display: flex;
          flex-direction: column;
          //gap: 10px;

          .link a {
            //border-top: 1px solid #b9b9b9;
            display: flex;
            gap: 10px;
            align-items: center;
            justify-content: flex-start;
            flex-direction: row;
            text-decoration: none;
            color: #313131;
          }

          .link a {
            padding: 15px 20px 15px 15px;
            cursor: pointer;
            border-top: 1px solid transparent;
            border-bottom: 1px solid transparent;
          }

          .link a.selected {
            background: #dadada;
            border-top: 1px solid #b9b9b9;
            border-bottom: 1px solid #b9b9b9;
          }

          .link a:hover {
            background: #dadada;
          }

          .link:hover a {
            color: #000000;
          }

        }
      }

      .content {
        //width: calc(100% - 185px);
        width: 100%;

        .pageWrapper {
          height: calc(100vh - 45px);
          overflow: auto;
        }

        .pageHeading {
          display: flex;
          font-size: 20px;
          padding: 10px 20px;
          background: rgba(3, 38, 60, 0.8980392157);
          color: #FFF;
          flex-direction: row;
          justify-content: space-between;

          .left, .right {
            //width: 330px;
          }
          .right {
            cursor: pointer;
            display: flex;
            font-size: 15px;
            text-align: right;
            align-items: center;
            justify-content: flex-end;
            gap: 30px;

            a {
              gap: 5px;
              display: flex;
              flex-direction: row;
              align-items: center;
              color: #FFF;
              text-decoration: none;

              svg path {
                stroke: #FFF;
              }
            }

          }
          .pageTitle {
            text-align: center;
          }
        }

        .noContent {
          width: 100%;
          min-height: 50px;
          text-align: center;
          background: #ededed;
          display: flex;
          align-items: center;
          justify-content: center;

          flex-direction: column;
          gap: 15px;
          padding: 20px 20px;
          box-sizing: border-box;
        }

        .tableSearch {
          width: 100%;
          font-weight: 500;

          form {
            display: flex;
            flex-wrap: wrap;
            flex-direction: row;
            column-gap: 20px;
          }

          .submitRow {
            width: 100% !important;

            .ant-form-item-control-input-content {
              flex: auto;
              max-width: 100%;
              display: flex;
              align-items: center;
              justify-content: center;

              button {
                padding: 0 50px;
              }
            }
          }
        }

        .pageContent {
          height: calc(100% - 44px);
          overflow: auto;
          padding: 25px 30px;
          box-sizing: border-box;
          max-width: calc(100% - 40px);
          background: #fff;
          margin: 0 auto;
          margin-top: 20px;
          border-radius: 5px;
        }

        .pageContentFullWidth {
          height: calc(100% - 44px);
          overflow: auto;
          max-width: calc(100% - 40px);
          padding: 25px 30px;
          box-sizing: border-box;
          background: #fff;
          margin: 0 auto;
          margin-top: 20px;
          border-radius: 5px;
          box-sizing: border-box;
        }
      }
    }
  }
}
