.moduleWrapper {
  :global {
    .twoColumns {
      justify-content: center;
    }

    .labelDate {
      display: flex;
      width: 100%;
      justify-content: space-between;
      flex-direction: row;
    }

    .quickFilter {
      display: flex;
      gap: 10px;
      div {
        cursor: pointer;
        &:hover {
          color: #d97100;
        }
      }
    }

    .formFieldDate label {
      width: 100%;
    }

    .statuses {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      gap: 6px;
      flex-wrap: wrap;
      //max-width: 960px;
      margin: 0 auto;

      .status {
        //width: 120px;
        border: 1px solid #bbbbbb;
        height: 60px;
        padding: 10px;
        border-radius: 10px;
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 5px;
        background: #ffd4a72b;

        .counter {
          font-size: 30px;
        }
      }
    }
  }
}
